:root {
    --client-fnt-color: #120d0d;
    --client-header-color: #159D3E;
    --client-btn-color: #159D3E;
    --client-btn-inactive: #999;
    --client-btn-border-color: #159D3E;
    --client-btn-hover: #80C494;
    --client-clr-info: #159D3E;
    --client-clr-warning: darkred;
    --client-clr-selected: rgba(128, 196, 148, 0.2);
    --client-pgrs-notstarted: #ca3120;
    --client-pgrs-started: #357a32;
    --client-pgrs-completed: #0d6efd;
    --client-thead-color: #e6f2ff;
}
